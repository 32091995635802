import { trackEvent } from "@leafly-com/web-utils";
import classnames from "classnames";

import { Action, Category } from "constants/events";
import { Deal } from "custom-types/Deals";
import { Dispensary } from "custom-types/Dispensary";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import useFilters from "hooks/useFilters";
import useGetDealKindLabel from "hooks/useGetDealKindLabel";
import { useSelector } from "redux/hooks";
import { getUserId, getUserPrefersMedical } from "redux/selectors/user";
import isDispensaryDualLicense from "utils/dispensary/isDispensaryDualLicense";
import { dispensaryHref } from "utils/dispensaryUrls";
import formatDeals from "utils/formatDeals";

import DealCard from "components/botanic//DealCard";
import Carousel from "components/botanic/Carousel";

type Props = {
  cardsPerSlide?: number;
  className?: string;
  dealCardLinks?: boolean;
  deals: Deal[];
  dispensary: Dispensary;
};

const ActiveDealsCarousel: React.FC<Props> = ({
  cardsPerSlide = 4,
  className,
  dealCardLinks,
  deals,
  dispensary,
}) => {
  const countryCode = useDomainCountryCode();
  const {
    id: dispensaryId,
    name: dispensaryName,
    slug: dispensarySlug,
    retailType,
    tags,
  } = dispensary;
  const isDualLicenseDispensary = isDispensaryDualLicense(tags);
  const userPrefersMedical = useSelector(getUserPrefersMedical);

  const { todaysDeals, todaysMedDeals, todaysRecDeals } = formatDeals(deals);

  const activeDeals = isDualLicenseDispensary
    ? userPrefersMedical
      ? todaysMedDeals
      : todaysRecDeals
    : todaysDeals;

  const userId = useSelector(getUserId);

  const dealsPageHref = dispensaryHref({
    additionalPath: "/deals",
    countryCode,
    retailType,
    slug: dispensarySlug,
  });

  const { toggleFilter } = useFilters();

  const { publishEvent } = useEventTracker();
  const { getDealKindLabel } = useGetDealKindLabel();

  const trackShopAll = () => {
    trackEvent(
      "Dispensary Menu Deals Show All",
      "click",
      `${dispensaryName}-${dispensaryId}`,
      { dispensaryId, userId },
    );
  };

  const dealCardOnClick = (deal: Deal) => {
    if (!dealCardLinks) {
      // * Square brace required, see: components/MenuFilters/MenuFilterGroups/FilterGroups/FilterGroups.tsx:L54
      toggleFilter({ [`deal_title[]`]: deal.title });
    }

    publishEvent({
      action: Action.click,
      category: Category.dealCards,
      dealId: deal.id,
      dispensaryId,
      label: getDealKindLabel(deal),
      userId,
    });
  };

  const dealCardOnLoad = (deal: Deal) => {
    publishEvent({
      action: Action.impression,
      category: Category.dealCards,
      dealId: deal.id,
      dispensaryId,
      label: getDealKindLabel(deal),
      userId,
    });
  };

  return activeDeals.length ? (
    <Carousel
      className={classnames("w-full", className)}
      cardsPerSlide={cardsPerSlide}
      seeMoreText={"Shop all weed deals"}
      seeMoreHref={dealsPageHref}
      seeMoreOnClick={trackShopAll}
      title={<h2 className="heading--m font-extrabold">{"Weed deals"}</h2>}
    >
      {activeDeals.map((deal) => (
        <DealCard
          as={dealCardLinks ? "a" : "div"}
          className="rounded h-full overflow-hidden text-left shadow-low cursor-pointer"
          data-testid="active-deals-carousel__card"
          deal={{
            ...deal,
            dealUrl: dealCardLinks ? deal.link : undefined,
          }}
          key={deal.id}
          onClick={() => dealCardOnClick(deal)}
          onLoad={() => dealCardOnLoad(deal)}
          showMenuType
        />
      ))}
    </Carousel>
  ) : (
    <></>
  );
};

export default ActiveDealsCarousel;
