import React, { ButtonHTMLAttributes, InputHTMLAttributes } from "react";
import classNames from "classnames";

import SearchIcon from "components/Icons/search.svg";
import CloseIcon from "components/Icons/x.svg";

interface Props {
  wrapperClassName?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  searchButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  clearButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
}

const SearchInput: React.FC<Props> = ({
  wrapperClassName,
  inputProps: { value, className, ...otherInputProps } = {},
  searchButtonProps = {},
  clearButtonProps = {},
}) => (
  <div className={classNames("relative flex-auto", wrapperClassName)}>
    <input
      className={classNames(
        "bg-leafly-white border border-light-grey px-md py-sm text-sm rounded pr-[96px]",
        {
          "search-bar__background": value,
        },
        className,
      )}
      data-testid="leafly-search-input"
      aria-label="search"
      type="search"
      value={value}
      {...otherInputProps}
    />
    <div className="absolute top-0 right-0 flex h-full">
      {value && (
        <button
          aria-label="Close"
          data-testid="leafly-search-input-clear"
          className="px-md"
          {...clearButtonProps}
        >
          <CloseIcon height="24" width="24" />
        </button>
      )}
      <button
        aria-label="Search"
        className="px-md"
        data-testid="leafly-search-input-button"
        {...searchButtonProps}
      >
        <SearchIcon height="24" width="24" />
      </button>
    </div>
    <style jsx>{`
      .search-bar__background {
        background: linear-gradient(
          270deg,
          var(--color-yellow) 48px,
          white 48px
        );
      }
    `}</style>
  </div>
);

export default SearchInput;
