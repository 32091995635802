import { useSelector } from "redux/hooks";
import {
  getIsOrderingAllowed,
  getIsThirdPartyOrderingProhibited,
} from "redux/selectors/complianceRules";

import NonCompliantMenu from "../NonCompliantMenu";

const MenuNotAvailable: React.FC<{
  dispensaryId: number;
  dispensaryName: string;
  dispensaryFeatureTier: string;
  dispensaryRetailType: string;
}> = ({
  dispensaryId,
  dispensaryName,
  dispensaryFeatureTier,
  dispensaryRetailType,
}) => {
  const thirdPartyOrderingProhibited = useSelector(
    getIsThirdPartyOrderingProhibited,
  );
  const isInfoTier = dispensaryFeatureTier === "info";
  const isDispensaryRetailType = dispensaryRetailType === "dispensary";

  // * Only "dispensary" types are restricted, since clinic and cbd-store types
  // * typically don't sell restricted items.
  const isLeaflyOrderingAllowed =
    useSelector(getIsOrderingAllowed) || !isDispensaryRetailType;

  return !thirdPartyOrderingProhibited &&
    !isLeaflyOrderingAllowed &&
    isDispensaryRetailType ? (
    <NonCompliantMenu
      dispensaryId={dispensaryId}
      dispensaryName={dispensaryName}
      hideCTA={isInfoTier}
      thirdPartyOrderingProhibited={thirdPartyOrderingProhibited}
      isLeaflyOrderingAllowed={isLeaflyOrderingAllowed}
    />
  ) : null;
};

export default MenuNotAvailable;
